import React, { useState, useEffect } from 'react';
import TaskModal from './TaskModal';
import { useTranslation } from 'react-i18next'; // Add translation hook

const StatsAndOpportunities = ({ onShowSearch }) => {
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    active_org_count: 0,
    active_tasks_count: 0,
  });
  const { t } = useTranslation(); // Add translation hook

  const fetchStats = async () => {
    try {
      const response = await fetch('https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net/organsiations/org/dashboard/stats');
      if (!response.ok) {
        throw new Error(t('errors.fetch_stats_failed'));
      }
      const data = await response.json();
      setStats({
        active_org_count: data.active_org_count,
        active_tasks_count: data.active_tasks_count,
      });
    } catch (err) {
      setError(t('errors.failed_to_load_stats'));
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await fetch('https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net/tasks/tasks');
      if (!response.ok) {
        throw new Error(t('errors.fetch_tasks_failed'));
      }
      const data = await response.json();
      setTasks(data.slice(0, 3));
    } catch (err) {
      setError(t('errors.failed_to_load_tasks'));
    }
  };

  const refreshTasks = async () => {
    await fetchTasks();
  };

  useEffect(() => {
    fetchStats();
    fetchTasks();
  }, []);

  const handleLearnMore = (task) => {
    setSelectedTask(task);
  };

  const handleCloseModal = () => {
    setSelectedTask(null);
  };

  return (
    <div className="py-10 sm:py-16">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
          <div className="bg-[#FFF7D4] rounded-lg p-6 text-center shadow-md">
            <span className="text-4xl font-bold block">{stats.active_org_count}</span>
            <span className="text-sm uppercase">{t('stats.organizations')}</span>
          </div>
          <div className="bg-[#FFF7D4] rounded-lg p-6 text-center shadow-md">
            <span className="text-4xl font-bold block">{stats.active_tasks_count}</span>
            <span className="text-sm uppercase">{t('stats.activities_to_choose')}</span>
          </div>
        </div>

        {/* <h2 className="text-3xl font-bold mb-4 text-center">{t('stats.volunteer_a_day')}</h2> */}

        <div className="text-center mb-8">
          <button
            className="bg-[#FEE372] text-black px-8 py-3 rounded-full hover:bg-[#FEE372] transition-colors duration-200"
            onClick={() => onShowSearch()}
          >
            {t('stats.find_opportunities')}
          </button>
        </div>


        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
  {error ? (
    <p className="text-red-500 text-center">{error}</p>
  ) : (
    tasks.map((task) => (
      <div key={task.id} className="bg-blue-100 rounded-lg shadow-md overflow-hidden">
        <div className="relative">
          <img
            src={task.thumbnail_url || '/default-thumbnail.jpg'}
            alt={task.title}
            className="w-full h-40 object-cover"
          />
          <div className="absolute top-2 right-2 flex flex-col gap-1">
            {task.volunteers_required && parseInt(task.volunteers_required) > 1 && (
              <span className="bg-blue-500 text-white text-xs px-2 py-1 rounded-full shadow-sm">
                {t('common.volunteer_with_friends')}
              </span>
            )}
          </div>
        </div>
        <div className="p-4">
          {/* Title */}
          <h3 className="text-lg font-bold text-gray-800 mb-3 truncate">{task.title}</h3>
          
          {/* Description */}
          <div className="mb-4 border-l-4 border-yellow-400 pl-3">
            <p className="text-sm text-gray-700 line-clamp-3 leading-relaxed">
              {task.description}
            </p>
          </div>

          {/* Organization Name */}
          <p className="text-sm text-gray-600 mb-1">
            <strong>{t('filters.organization')}:</strong> {task.organisation_details?.organisation_name || t('common.not_specified')}
          </p>

          {/* Location with Region */}
          <p className="text-sm text-gray-600 mb-1">
            <strong>{t('filters.location')}:</strong> {' '}
            {task.is_remote ? t('filters.remote') : (
              <>
                {task.location}
                {task.region && ` (${task.region})`}
              </>
            )}
          </p>

          {/* Frequency */}
          <p className="text-sm text-gray-600 mb-1">
            <strong>{t('filters.frequency')}:</strong> {' '}
            {task.frequency || t('common.not_specified')}
          </p>

          {/* Type and Family Friendly on same line */}
          <div className="flex justify-between text-sm text-gray-600 mb-3">
            <span>
              <strong>{t('filters.task_type')}:</strong> {task.is_one_time ? t('filters.one_time') : t('filters.ongoing')}
            </span>
            <span>
              <strong>{t('filters.family_friendly')}:</strong> {task.is_family_friendly ? t('filters.yes') : t('filters.no')}
            </span>
          </div>

          {/* Tags */}
          <div className="flex flex-wrap gap-2 mb-4 pt-2 border-t border-gray-200">
            {task.tags.map((tag, index) => (
              <span 
                key={index}
                className="text-xs bg-yellow-200 text-gray-700 px-3 py-1 rounded-full font-medium"
              >
                {tag}
              </span>
            ))}
          </div>

          <button
            className="w-full bg-[#FEE372] text-black px-4 py-2 rounded-full hover:bg-[#FEE372]/80 transition-colors duration-200 text-sm font-medium"
            onClick={() => handleLearnMore(task)}
          >
            {t('stats.learn_more')}
          </button>
        </div>
      </div>
    ))
  )}
</div>

        {selectedTask && <TaskModal task={selectedTask} onClose={handleCloseModal} refreshTasks={refreshTasks} />}
      </div>
    </div>
  );
};

export default StatsAndOpportunities;
