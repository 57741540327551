import React, { useState, useEffect } from 'react';
import { useCMS } from '../contexts/CMSContext';
import { useTranslation } from 'react-i18next';
import TaskModal from './TaskModal';
import { ClipLoader } from 'react-spinners';
import Select from 'react-select';

const LOCATIONS = [
  { value: 'Central Malta', label: 'Central Malta' },
  { value: 'North Malta', label: 'North Malta' },
  { value: 'South Malta', label: 'South Malta' },
  { value: 'West Malta', label: 'West Malta' },
  { value: 'Gozo', label: 'Gozo' }
];

const ActiveTasks = () => {
  const { t } = useTranslation();
  const cmsContent = useCMS();
  const [activeTasks, setActiveTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTask, setSelectedTask] = useState(null);

  const [filters, setFilters] = useState({
    categories: [], // Array for multiple categories
    locations: [], // Array for multiple locations
    taskType: '',
    isRemote: false,
    isOnsite: false,
    isFamilyFriendly: false,
    withFriends: false,
    sortBy: 'relevance'
  });

  const categories = cmsContent.interestedCategories || [
    t('categories.administration'),
    t('categories.animal_care'),
    t('categories.anything_with_food'),
    t('categories.educational'),
    t('categories.environmental_cultural'),
    t('categories.family_friendly'),
    t('categories.maintenance'),
    t('categories.retail'),
    t('categories.social_care_giving'),
    t('categories.sports_related'),
    t('categories.other')
  ];

  const categoriesOptions = categories.map(category => ({
    value: category,
    label: category
  }));

  const SORT_OPTIONS = [
    { value: 'relevance', label: t('filters.sort_relevance') },
    { value: 'expiry_latest', label: t('filters.sort_expiry_latest') },
    { value: 'expiry_newest', label: t('filters.sort_expiry_newest') },
    { value: 'org_alpha', label: t('filters.sort_org_alpha') }
  ];

  useEffect(() => {
    const userString = localStorage.getItem('user');
    if (userString) {
      const userObject = JSON.parse(userString);
      setUser(userObject);
    }
  }, []);

  const fetchActiveTasks = async () => {
    if (!user) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const response = await fetch(`https://joyfromgivingapi-d3hkgkftbth7bcgd.westeurope-01.azurewebsites.net/tasks/users/${user.userId}/active_tasks`);
      if (!response.ok) {
        throw new Error(t('errors.failed_to_fetch_tasks'));
      }
      const data = await response.json();
      setActiveTasks(data);
      setFilteredTasks(data);
    } catch (err) {
      console.error(t('errors.fetch_tasks_failed'), err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchActiveTasks();
  }, [user]);

  const refreshTasks = async () => {
    await fetchActiveTasks();
  };

  useEffect(() => {
    const filtered = activeTasks.filter((task) => {
      const matchesSearch = task.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          task.description.toLowerCase().includes(searchQuery.toLowerCase());
      
      const matchesCategories = filters.categories.length === 0 || 
                              filters.categories.some(cat => task.tags.includes(cat.value));
      
      const matchesLocations = filters.locations.length === 0 ||
                              filters.locations.some(loc => task.region === loc.value);
      
      const matchesTaskType = !filters.taskType || 
                            (filters.taskType === 'One-time' && task.is_one_time) ||
                            (filters.taskType === 'Ongoing' && task.is_ongoing);
      
      const matchesRemoteOnsite = (!filters.isRemote && !filters.isOnsite) ||
                                (filters.isRemote && task.is_remote) ||
                                (filters.isOnsite && !task.is_remote);
      
      const matchesFamilyFriendly = !filters.isFamilyFriendly || task.is_family_friendly;
      
      const matchesWithFriends = !filters.withFriends || 
                                (task.volunteers_required && parseInt(task.volunteers_required) > 1);

      return matchesSearch && matchesCategories && matchesLocations && 
             matchesTaskType && matchesRemoteOnsite && matchesFamilyFriendly && 
             matchesWithFriends;
    });

    // Sort filtered tasks
    const sorted = [...filtered].sort((a, b) => {
      switch (filters.sortBy) {
        case 'expiry_latest':
          return new Date(b.expires) - new Date(a.expires);
        case 'expiry_newest':
          return new Date(a.expires) - new Date(b.expires);
        case 'org_alpha':
          return (a.organisation_details?.organisation_name || '').localeCompare(
            b.organisation_details?.organisation_name || ''
          );
        default: // relevance
          return 0;
      }
    });

    setFilteredTasks(sorted);
  }, [searchQuery, filters, activeTasks]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterChange = (name, value) => {
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleViewDetails = (task) => {
    setSelectedTask(task);
  };

  const handleCloseModal = () => {
    setSelectedTask(null);
  };
  
  if (!user) {
    return (
      <div className="flex justify-center items-center min-h-[400px] text-gray-600 text-lg">
        {t('errors.login_to_see_tasks')}
      </div>
    );
  }

  return (
    <div className="bg-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
          {t('active_tasks.title')}
        </h2>

        <div className="mb-6">
          <input
            type="text"
            className="w-full p-4 rounded-lg shadow-lg bg-gray-100 text-gray-700 focus:outline-none focus:ring-2 focus:ring-yellow-400 border border-gray-300"
            placeholder={t('search.placeholder')}
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>

        <div className="space-y-6 mb-8">
          {/* Categories Multi-select */}
          <div>
            <label className="block text-gray-700 font-semibold mb-2">
              {t('filters.category')}
            </label>
            <Select
              isMulti
              options={categoriesOptions}
              value={filters.categories}
              onChange={(value) => handleFilterChange('categories', value)}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={t('filters.all_categories')}
            />
          </div>

          {/* Locations Multi-select */}
          <div>
            <label className="block text-gray-700 font-semibold mb-2">
              {t('filters.location')}
            </label>
            <Select
              isMulti
              options={LOCATIONS}
              value={filters.locations}
              onChange={(value) => handleFilterChange('locations', value)}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={t('filters.all_locations')}
            />
          </div>

          {/* Mode and Additional Options */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2">
              <label className="block text-gray-700 font-semibold">
                {t('filters.mode')}
              </label>
              <div className="flex flex-wrap gap-4">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={filters.isRemote}
                    onChange={(e) => handleFilterChange('isRemote', e.target.checked)}
                    className="rounded border-gray-300 text-yellow-400 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-200 focus:ring-opacity-50"
                  />
                  <span className="ml-2">{t('filters.remote')}</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={filters.isOnsite}
                    onChange={(e) => handleFilterChange('isOnsite', e.target.checked)}
                    className="rounded border-gray-300 text-yellow-400 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-200 focus:ring-opacity-50"
                  />
                  <span className="ml-2">{t('filters.on_site')}</span>
                </label>
              </div>
            </div>

            <div className="space-y-2">
              <label className="block text-gray-700 font-semibold">
                {t('filters.additional_options')}
              </label>
              <div className="flex flex-wrap gap-4">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={filters.isFamilyFriendly}
                    onChange={(e) => handleFilterChange('isFamilyFriendly', e.target.checked)}
                    className="rounded border-gray-300 text-yellow-400 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-200 focus:ring-opacity-50"
                  />
                  <span className="ml-2">{t('filters.family_friendly')}</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={filters.withFriends}
                    onChange={(e) => handleFilterChange('withFriends', e.target.checked)}
                    className="rounded border-gray-300 text-yellow-400 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-200 focus:ring-opacity-50"
                  />
                  <span className="ml-2">{t('filters.with_friends')}</span>
                </label>
              </div>
            </div>
          </div>

          {/* Task Type and Sort Options */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700 font-semibold mb-2">
                {t('filters.task_type')}
              </label>
              <select
                className="w-full p-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                value={filters.taskType}
                onChange={(e) => handleFilterChange('taskType', e.target.value)}
              >
                <option value="">{t('filters.all_types')}</option>
                <option value="One-time">{t('filters.one_time')}</option>
                <option value="Ongoing">{t('filters.ongoing')}</option>
              </select>
            </div>

            <div>
              <label className="block text-gray-700 font-semibold mb-2">
                {t('filters.sort_by')}
              </label>
              <select
                className="w-full p-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                value={filters.sortBy}
                onChange={(e) => handleFilterChange('sortBy', e.target.value)}
              >
                {SORT_OPTIONS.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {error && (
          <div className="flex justify-center items-center min-h-[200px] text-red-500 text-lg">
            {t('errors.fetch_tasks_failed')}
          </div>
        )}

        {isLoading ? (
          <div className="flex justify-center items-center min-h-[400px]">
            <ClipLoader
              size={50}
              color={"#FEE372"}
              loading={isLoading}
              aria-label="Loading Spinner"
            />
          </div>
        ) : (
          <>
            {filteredTasks.length === 0 ? (
              <div className="flex justify-center items-center min-h-[200px] text-gray-500 text-lg">
                {t('active_tasks.no_tasks_found')}
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredTasks.map((task) => (
                  <div key={task.id} className="bg-blue-100 rounded-lg shadow-md overflow-hidden">
                    <div className="relative">
                      <img
                        src={task.thumbnail_url || '/default-thumbnail.jpg'}
                        alt={task.title}
                        className="w-full h-40 object-cover"
                      />
                      <div className="absolute top-2 right-2 flex flex-col gap-1">
                        {task.volunteers_required && parseInt(task.volunteers_required) > 1 && (
                          <span className="bg-blue-500 text-white text-xs px-2 py-1 rounded-full shadow-sm">
                            {t('common.volunteer_with_friends')}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="p-4">
                      <h3 className="text-lg font-bold text-gray-800 mb-3 truncate">{task.title}</h3>
                      
                      <div className="mb-4 border-l-4 border-yellow-400 pl-3">
                        <p className="text-sm text-gray-700 line-clamp-3 leading-relaxed">
                          {task.description}
                        </p>
                      </div>

                      <p className="text-sm text-gray-600 mb-1">
                      <strong>{t('filters.organization')}:</strong> {task.organisation_details?.organisation_name || t('common.not_specified')}
                      </p>

                      <p className="text-sm text-gray-600 mb-1">
                        <strong>{t('filters.location')}:</strong> {' '}
                        {task.is_remote ? t('filters.remote') : (
                          <>
                            {task.location}
                            {task.region && ` (${task.region})`}
                          </>
                        )}
                      </p>

                      <p className="text-sm text-gray-600 mb-1">
                        <strong>{t('filters.frequency')}:</strong> {' '}
                        {task.frequency || t('common.not_specified')}
                      </p>

                      <div className="flex justify-between text-sm text-gray-600 mb-3">
                        <span>
                          <strong>{t('filters.task_type')}:</strong> {task.is_one_time ? t('filters.one_time') : t('filters.ongoing')}
                        </span>
                        <span>
                          <strong>{t('filters.family_friendly')}:</strong> {task.is_family_friendly ? t('filters.yes') : t('filters.no')}
                        </span>
                      </div>

                      <div className="flex flex-wrap gap-2 mb-4 pt-2 border-t border-gray-200">
                        {task.tags.map((tag, index) => (
                          <span 
                            key={index}
                            className="text-xs bg-yellow-200 text-gray-700 px-3 py-1 rounded-full font-medium"
                          >
                            {tag}
                          </span>
                        ))}
                      </div>

                      <button
                        className="w-full bg-[#FEE372] text-black px-4 py-2 rounded-full hover:bg-[#FEE372]/80 transition-colors duration-200 text-sm font-medium"
                        onClick={() => handleViewDetails(task)}
                      >
                        {t('active_tasks.learn_more')}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}

        {selectedTask && (
          <TaskModal 
            task={selectedTask} 
            onClose={handleCloseModal} 
            refreshTasks={refreshTasks} 
          />
        )}
      </div>
    </div>
  );
};

export default ActiveTasks;